<template>
  <div>
      <v-data-table
        v-model="selected"
        :headers="activeTableHeader"
        :items="recordItems"
        :loading="$wait.is('fetch records')"
        :options.sync="tableOptions"
        :footer-props="footerProps"
        :server-items-length="totalRecordItems"
        :item-key="groupTemplates || leaMaster ? 'id' : 'uniqueId'"
        mobile-breakpoint="980"
        :show-select="multiSelectionMode"
        :no-data-text="$t('vvtList.noResult')"
        :height="tableHeight"
        fixed-header
        class="data-table--row-cursor-pointer pt-2"
        @click:row="$emit('select-record', $event)"
    >
    <template v-slot:loading>
      <div v-if="$wait.is('fetch init') || $wait.is('fetch records')" class="py-0 pl-0">
        <v-skeleton-loader
            loading
            :type="'table-tbody, table-tfoot'"
            tile
            elevation
            height="260"
            class="d-block"
        ></v-skeleton-loader>
      </div>
    </template>
    <template v-slot:[`item.updatedAt`]="{ item }">
      {{ parseDate(item.updatedAt) }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip v-if="item.status" label small :color="$getStatusColorClass(item.status)">{{ $t('states.' + item.status.toLowerCase()) }}</v-chip>
    </template>
    <template v-slot:[`item.internalId`]="{ item }">
      <div class="word-break--all">{{ item.internalId }}</div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <div class="word-break--all" style="min-width:160px;">{{ item.name }}</div>
    </template>
    <template v-slot:[`header.coordinators`]="{ header }">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ header.text }}</span>
        </template>
        <span>{{ $t('vvtList.recordPreview.processCoordinator.title') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.coordinators`]="{ item }">
      <div class="word-break--all" style="min-width:160px;">{{ item.coordinators }}</div>
    </template>
    <template v-slot:[`item.editors`]="{ item }">
      <div class="word-break--all" style="min-width:160px;">{{ item.editors }}</div>
    </template>
    <template v-slot:[`item.processOwners`]="{ item }">
      <div class="word-break--all" style="min-width:160px;">{{ item.processOwners }}</div>
    </template>
    <template v-slot:[`item.priorityState`]="{ item }">
      <v-chip v-if="item.priorityState" label small :color="$getPriorityColorClass(item.priorityState)">{{ item.priorityState }}</v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        icon
        @click.stop
        :to="{
            name: groupTemplates ? 'SettingsVvtGroupTemplatesEdit' : leaMaster ? 'AdminVvtEditMaster' :  'VvtEditProcessingActivity',
            params: {
                id: item.id,
            },
        }">
        <v-icon color="primary" v-text="'mdi-file-document-edit-outline'" />
      </v-btn>
    </template>
    <template v-slot:[`footer.prepend`]>
      <div class="pre-pagination"></div>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';

const LEA_VVT_LIST_TABLE_PAGE = 'LEA_VVT_LIST_TABLE_PAGE';
const LEA_VVT_LIST_TABLE_SORTBY = 'LEA_VVT_LIST_TABLE_SORTBY';
const LEA_VVT_LIST_TABLE_SORTDESC = 'LEA_VVT_LIST_TABLE_SORTDESC';

export default {
  props: {
    recordItems: {
      type: Array,
      default: () => []
    },
    selectedRecord: {
      type: Object,
      default: null
    },
    totalRecordItems: {
      type: Number,
      default: 0
    },
    groupTemplates: {
      type: Boolean,
      default: false
    },
    multiSelectionMode: {
      type: Boolean,
      default: false
    },
    selectedRecords: {
      type: Array,
      default: () => []
    },
    leaMaster: {
      type: Boolean,
      default: false
    },
    page: {
        type: Number,
        default: 1
    },
    initialTableSortby: {
      type: String,
      default: null
    },
    initialTableSortDesc: {
      type: Boolean,
      default: false
    },
    limit: {
        type: Number,
        default: 10
    },
    grouping: {
      type: String,
      default: 'company'
    },
    selectedDomain: {
      type: Object,
      default: null
    },
    selectedCompany: {
      type: Object,
      default: null
    }
  },
  i18n: {
      messages: {
          en: require('@/locales/vvt/List/en.json'),
          de: require('@/locales/vvt/List/de.json'),
      },
  },
  data () {
    return {
      tableOptions: {
        page: this.page,
        sortBy: [this.initialTableSortby],
        sortDesc: [this.initialTableSortDesc],
        itemsPerPage: this.limit,
      },
      footerProps: {
          itemsPerPageOptions: [this.limit],
          itemsPerPageText: this.$t('perPage'),
          pageText: '{0}-{1} ' + this.$t('from') + ' {2}'
          // pageText: ''
      },
      defaultListHeaders: [
        { text: this.$t('vvtList.recordPreview.actions'), value: 'actions', sortable: false, divider: true, width:'80', 'displayFrom': 'xs' },
        { text: 'ID', value: 'internalId', width:'100', 'displayFrom': 'md' },
        { text: this.$t('name'), align: 'start', value: 'name', 'displayFrom': 'xs' },
        { text: this.$t('vvtList.recordPreview.companyName'), value: 'companyName', 'displayFrom': 'md' },
        { text: this.$t('department'), value: 'departmentTitle', 'displayFrom': 'xl' },
        { text: this.$t('domain'), value: 'domainTitle', 'displayFrom': 'xl' },
        { text: this.$t('vvtList.recordPreview.processCoordinator.short'), value: 'coordinators', 'displayFrom': 'xl' },
        { text: this.$t('vvtList.recordPreview.processEditor'), value: 'editors', 'displayFrom': 'xl' },
        { text: this.$t('vvtList.recordPreview.processOwner'), value: 'processOwners', 'displayFrom': 'xl' },
        { text: this.$t('updated'), value: 'updatedAt', 'displayFrom': 'xl', width: '140px' },
        { text: this.$t('vvtList.recordPreview.status'), value: 'status', 'displayFrom': 'lg' },
        { text: this.$t('vvtList.recordPreview.priority'), value: 'priorityState', width:'100', 'displayFrom': 'lg' }
      ],
      templateHeaders: [
        { text: this.$t('vvtList.recordPreview.actions'), value: 'actions', divider: true, width:'80' },
        { text: 'ID', value: 'internalId', width:'100' },
        { text: this.$t('vvtList.recordPreview.status'), value: 'status' },
        { text: this.$t('name'), align: 'start', value: 'name' },
        { text: this.$t('domain'), align: 'start', value: 'domainTitle' },
        { text: this.$t('updated'), value: 'updatedAt', width:'140px'},
      ],
      leaMasterHeaders: [
        { text: this.$t('vvtList.recordPreview.actions'), value: 'actions', divider: true, width:'80' },
        { text: 'ID', value: 'internalId', width:'100' },
        { text: this.$t('name'), align: 'start', value: 'name' },
        { text: this.$t('departmentCategory'), align: 'start', value: 'departmentTitle' },
        { text: this.$t('vvtList.recordPreview.status'), value: 'status', 'displayFrom': 'lg' },
        { text: this.$t('updated'), value: 'updatedAt', width:'140px' },
      ]
    }
  },
  computed: {
    ...mapGetters({
        getCompanyGroupSettings: 'companyGroupSettings/get'
    }),
    tableHeight () {
      let val = this.$vuetify.application.top + this.$footerHeight() + 36 + 20;
      return 'calc(100vh - ' + val + 'px)';
    },
    activeTableHeader () {
      return this.groupTemplates ? this.templateHeaders : this.leaMaster ? this.leaMasterHeaders : this.listHeaders;
    },
    listHeaders () {
        let headers = this.defaultListHeaders;
        if (this.$vuetify.breakpoint.smAndDown) {
            headers = this.defaultListHeaders.filter(x => ['xs'].includes(x.displayFrom));
        }
        if (this.$vuetify.breakpoint.mdAndUp) {
            headers = this.defaultListHeaders.filter(x => ['xs', 'md'].includes(x.displayFrom));
        }
        if (this.$vuetify.breakpoint.lgAndUp) {
            headers = this.defaultListHeaders.filter(x => ['xs', 'md', 'lg'].includes(x.displayFrom));
        }
        if (this.$vuetify.breakpoint.xl) {
            headers = this.defaultListHeaders.filter(x => ['xs', 'md', 'lg', 'xl'].includes(x.displayFrom));
        }

        if (
            (!this.selectedDomain || this.selectedDomain.id || this.selectedDomain.id === 'all') 
            && this.selectedCompany && this.selectedCompany.id && this.selectedCompany.id !== 'all'
          ) {
            headers = headers.filter(x => x.value !== 'companyName');
        }
        let removeCol = this.useDomainGroupedProcessingActivityList ? 'departmentTitle' : 'domainTitle';
        if (this.grouping === 'domain') {
          removeCol = 'domainTitle';
        }
        headers = headers.filter(x => x.value !== removeCol);

        if(!this.useWorkflow) {
            headers = headers.filter(x => x.value !== 'editors' && x.value !== 'processOwners' && x.value !== 'coordinators');
        }
        
        return headers;
    },
    useWorkflow () {
        return this.getCompanyGroupSettings('useWorkflow');
    },
    useDomainGroupedProcessingActivityList () {
        return this.getCompanyGroupSettings('domainGroupedProcessingActivityList');
    },
    selected: {
      get () {
        return this.selectedRecords;
      },
      set (val) {
        this.$emit('select-multiple-records', val);
      }
    }
  },
  methods: {
    getClassObject(recordId) {
        const objClass = {
            'primary--text':
                !this.selectedRecord || recordId !== this.selectedRecord.id,
            'primary white--text':
                this.selectedRecord && recordId === this.selectedRecord.id,
        };
        return objClass;
    },
    parseDate (date) {
      return formatDate(parseISO(date), dateTypes.shortDateTime)
    },
  },
  watch: {
    page (pageNew, pageOld) {
      if (pageNew !== pageOld) {
        this.tableOptions.page = pageNew;
      }
    },
    tableOptions: {
        handler(options, optionsOld) {
          let refetch = false;
          if (options.page !== optionsOld.page) {
            this.$setStorage(LEA_VVT_LIST_TABLE_PAGE, options.page);
            refetch = true;
          }
          if (options.sortBy !== optionsOld.sortBy) {
            this.$setStorage(LEA_VVT_LIST_TABLE_SORTBY, options.sortBy);
            refetch = true;
          }
          if (options.sortDesc !== optionsOld.sortDesc) {
            this.$setStorage(LEA_VVT_LIST_TABLE_SORTDESC, options.sortDesc);
            refetch = true;
          }
          if (refetch) {
            this.selected = [];
            this.$emit('refetch-records', options);
          }
        },
        deep: true,
    }
  }
}
</script>