var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"data-table--row-cursor-pointer pt-2",attrs:{"headers":_vm.activeTableHeader,"items":_vm.recordItems,"loading":_vm.$wait.is('fetch records'),"options":_vm.tableOptions,"footer-props":_vm.footerProps,"server-items-length":_vm.totalRecordItems,"item-key":_vm.groupTemplates || _vm.leaMaster ? 'id' : 'uniqueId',"mobile-breakpoint":"980","show-select":_vm.multiSelectionMode,"no-data-text":_vm.$t('vvtList.noResult'),"height":_vm.tableHeight,"fixed-header":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":function($event){return _vm.$emit('select-record', $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [(_vm.$wait.is('fetch init') || _vm.$wait.is('fetch records'))?_c('div',{staticClass:"py-0 pl-0"},[_c('v-skeleton-loader',{staticClass:"d-block",attrs:{"loading":"","type":'table-tbody, table-tfoot',"tile":"","elevation":"","height":"260"}})],1):_vm._e()]},proxy:true},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.updatedAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"label":"","small":"","color":_vm.$getStatusColorClass(item.status)}},[_vm._v(_vm._s(_vm.$t('states.' + item.status.toLowerCase())))]):_vm._e()]}},{key:"item.internalId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"word-break--all"},[_vm._v(_vm._s(item.internalId))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"word-break--all",staticStyle:{"min-width":"160px"}},[_vm._v(_vm._s(item.name))])]}},{key:"header.coordinators",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('vvtList.recordPreview.processCoordinator.title')))])])]}},{key:"item.coordinators",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"word-break--all",staticStyle:{"min-width":"160px"}},[_vm._v(_vm._s(item.coordinators))])]}},{key:"item.editors",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"word-break--all",staticStyle:{"min-width":"160px"}},[_vm._v(_vm._s(item.editors))])]}},{key:"item.processOwners",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"word-break--all",staticStyle:{"min-width":"160px"}},[_vm._v(_vm._s(item.processOwners))])]}},{key:"item.priorityState",fn:function(ref){
var item = ref.item;
return [(item.priorityState)?_c('v-chip',{attrs:{"label":"","small":"","color":_vm.$getPriorityColorClass(item.priorityState)}},[_vm._v(_vm._s(item.priorityState))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{
          name: _vm.groupTemplates ? 'SettingsVvtGroupTemplatesEdit' : _vm.leaMaster ? 'AdminVvtEditMaster' :  'VvtEditProcessingActivity',
          params: {
              id: item.id,
          },
      }},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-file-document-edit-outline')}})],1)]}},{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"pre-pagination"})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }